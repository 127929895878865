<template>
    <div>
        <div class="d-flex justify-content-center" style="align-items:center; position: absolute;
                top: -10%;
                left: 0;
                width: 100%;
                height: 100%;"> 
            <b-spinner class="m-5" style="font-size: 3rem;" label="Busy"></b-spinner>
        </div>
    </div>
</template>

<script>
    import { handleAuth, loggedIn, getToken } from '../../../authUtils.js'
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { SET_TOKEN, LOGIN, CLEAR_AUTH_COOKIES } from '../../../core/services/store/auth.module';

    export default {
        name: 'Callback',
        data: () => {
            return {
                isLogged: null,
                token: ''
            };
        },
        methods: {
            logInAuth() {
                this.$http.get('login/auth0').then(res => {
                    console.log('logging in')
                })
                .catch(err => {
                    console.log("Error::", err)
                })
            }
        },
        beforeMount() {
            // console.log("Callback beforemount -- setting token in store")
            handleAuth(error => {
                if (error) {
                    console.log('Error::', error);
                    this.$router.push('/');
                }
                this.token = getToken();
                // this.$store.dispatch(CLEAR_AUTH_COOKIES, this.token)
                this.$store.dispatch(SET_TOKEN, this.token)
                this.$store.dispatch(LOGIN, this.token)
                this.$router.push('/');
            });
        }
    };
</script>